/** @format */

.screen-container {
   display: grid;
   grid-template-columns: 20vw 1fr;
   width: 100%;
   grid-template-rows: auto 1fr;
   height: 100vh;
}

.screen-sidebar-container {
   grid-area: 1 / 1 / 3 / 2;
   overflow: hidden;
}

.screen-top-container {
   grid-area: 1 / 2 / 2 / 3;
   overflow: hidden;
}

.screen-content-container {
   grid-area: 2 / 2 / 3 / 3;
   overflow-y: scroll;
   background-color: #f2f4f6;
   padding-inline-start: 1rem;
   z-index: 1000;
   padding-top: 1.5rem;
   padding-bottom: 2rem;

   &::-webkit-scrollbar {
      width: 2px;
      border-radius: 158px 0px 0px 103px;
   }

   &::-webkit-scrollbar-track {
      border-radius: 158px 0px 0px 103px;
      margin-right: 0.3rem;
   }

   &::-webkit-scrollbar-thumb {
      background: #500472;
      border-radius: 158px 0px 0px 103px;
   }

   ::-webkit-scrollbar-thumb:hover {
      background: #b30000;
   }
}

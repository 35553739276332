.inputs-container {
  margin-top: 1rem;
}

.transfer-details-bank-row {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-family: Manrope;
  align-items: center;
}

.bank {
  color: #14142b;
  font-size: 0.9rem;
  font-weight: 600;
}

.to {
  color: #898995;
  font-size: 0.9rem;
}

.transfer-amount{
  color: #500472;
  font-weight: 700;
  font-size: 2.3rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modified-months {
  background-color: #f6f6f7;
  border-radius: 6px;
  padding-inline: 0.5rem;
  padding-block: 0.2rem;
}

.view-all-text {
  color: #1877f2;
  font-weight: 500;
  cursor: pointer;
}

.choose-action-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Manrope;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.choose-action-text {
  color: #898995;
  font-size: 0.9rem;
}

.remaining-amount-container {
  font-family: Manrope;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0.4rem;
  gap: 0.3rem;
  font-size: 0.8rem;
}

.remaining-amount {
  font-weight: bold;
}

.remaining-amount-text {
  color: #00c4a2;
}

.input-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  font-family: Manrope;
  margin-bottom: -1.5rem;
}

.expected-expense-name {
  font-size: 0.9rem;
}
.expected-expense-name-value {
  font-size: 0.9rem;
  font-weight: bold;
}

.impact-summary-table-container {
  font-family: Manrope;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 0.65rem;
  gap: 1px;
  justify-content: space-between;
}

.impact-summary-table-text {
  font-weight: 600;
  font-size: 0.8rem;
}

.balances-text {
  color: red;
  font-weight: 600;
}

.balance-container {
  display: inherit;
  gap: 6px;
}

.have-negative-balances-text {
  color: #898995;
}

.view-months-text {
  text-align: right;
  text-decoration-line: underline;
  color: #1877f2;
  cursor: pointer;
}

.goals-management-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

/** @format */

@import url("https://fonts.googleAPIs.com/css2?family=Manrope&display=swap");

.app-container {
   flex-direction: row;
   display: flex;
}

.MuiFormControlLabel-label {
   font-size: 0.9rem !important;
}

.input-modal-inputs-container {
   /* background-color: grey; */
   overflow-y: scroll;
   height: 90%;

   &::-webkit-scrollbar {
      width: 5px;
      border-radius: 158px 0px 0px 103px;
   }

   &::-webkit-scrollbar-track {
      border-radius: 158px 0px 0px 103px;
      margin-right: 0.3rem;
   }

   &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 158px 0px 0px 103px;
   }

   ::-webkit-scrollbar-thumb:hover {
      background: #b30000;
   }
}

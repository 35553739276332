/** @format */

.hide-completed-goals-text {
   color: #1877f2;
   font-family: Manrope;
   font-size: 0.8rem;
   text-align: right;
   cursor: pointer;
}

.progress-cards-container {
   margin-top: 1rem;
}

.progress-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  align-items: center;
}

.progress-card-details-container {
  display: flex;
  flex-direction: column;
  gap:0.3rem
}

.progress-card-title {
  font-family: Manrope;
  font-size: 1rem;
  font-weight: 700;
  color: #000000;
}

.progress-card-ends-in {
  font-family: Manrope;
  font-size: 0.8rem;
  font-weight: 400;
  color: #898995;
   display: flex;
    flex-direction: row;
    gap:0.2rem
}

.progress-card-saved-so-far {
  font-family: Manrope;
  font-size: 0.8rem;
  font-weight: 400;
  color: #1877f2;
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
}
.progress-card-remaining {
  font-family: Manrope;
  font-size: 0.8rem;
  font-weight: 400;
  color: #898995;
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
}

.bold {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 0.9rem;
}

/** @format */

.months-summary-row-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   /* background-color: red; */
   margin-bottom: 1rem;
   padding-right: 1rem;
}
.statistics-cards-container {
   display: flex;
   flex-direction: row;
   margin-bottom: 1rem;
   gap: 0.5rem;
}

.search-container {
   width: 30%;
}

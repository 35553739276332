/** @format */

#ctg-table-body {
   display: block;
   overflow: auto;
   border-collapse: collapse;
}
#ctg-table-header-container,
#ctg-table-body #ctg-table-tr {
   display: table;
   width: 100%;
   table-layout: fixed; /* even columns width , fix width of table too*/
   background-color: #ffffff;
}
#ctg-table-body {
   &::-webkit-scrollbar {
      width: 2px;
      border-radius: 158px 0px 0px 103px;
   }

   &::-webkit-scrollbar-track {
      border-radius: 158px 0px 0px 103px;
      margin-right: 0.3rem;
   }

   &::-webkit-scrollbar-thumb {
      background: #000000;
      border-radius: 158px 0px 0px 103px;
   }

   ::-webkit-scrollbar-thumb:hover {
      background: #b30000;
   }
}

#ctg-table {
   width: 100%;
   border-radius: 8px;
   border-collapse: collapse;
   margin-top: 1rem;
}

#ctg-table-th {
   padding: 0.3rem;
   font-size: 0.8rem;
   text-align: center;
   background-color: #ffffff;
   color: #898995;
   border: 1px solid #e5e7eb;
   font-family: Manrope;
   &:hover {
      background-color: rgba(137, 137, 149, 0.3);
   }
}

#ctg-table-header-container {
   width: calc(100% - 0em) /* scrollbar is average 1em/16px width, remove it from thead width */;
   overflow: hidden;
}

#ctg-table-td {
   text-align: center;
   background-color: #ffffff;
   color: #14142b;
   font-family: Manrope;
   font-weight: 600;
   padding: 0.7rem;
   font-size: 0.8rem;
   border: 1px solid #e5e7eb;
}

.converted-to-goal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.converted-to-goal-text {
  color: #12b769;
  font-size: 10px;
}

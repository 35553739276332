/** @format */

.inputRounded .MuiOutlinedInput-root {
   border-radius: 12px;
}

.select-label {
   color: #898995;
}

.select-labels-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   margin-top: 0.5rem;
   margin-bottom: 0.5rem;
   gap: 1rem;
}

.select-option-inactive-container {
   flex-grow: 1;
   text-align: center;
   padding: 0.7rem;
   border-radius: 8px;
   border: 1px solid #898995;
   font-size: 1rem;
   cursor: pointer;
}

.select-option-active-container {
   flex-grow: 1;
   text-align: center;
   padding: 0.7rem;
   border-radius: 8px;
   border: 1px solid #500472;
   font-size: 1rem;
   cursor: pointer;
   background-color: #eee6f1;
   color: #500472;
}

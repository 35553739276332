/** @format */

.input-modal-btns-container {
   display: flex;
   flex-direction: row;
   justify-content: center;
   gap: 1rem;
   margin-top: 2rem;
   align-items: center;
   bottom: 0.5rem;
   background-color: #ffffff;
   position: absolute;
   width: 90%;
   padding: 0.2rem;
}

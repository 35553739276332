.no-data-container {
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.no-data-container-message{
    font-size: 1.2rem;
    font-weight: 500;
    color: #898995;
}
.inline-alert-container {
  background-color: lightgrey;
  border-radius: 8px;
  padding: 1rem;
  border: 0px solid red;
  border-left-width: thick;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.info-container{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    font-size: 0.8rem;
    font-family: Manrope;
    align-items: center;
}
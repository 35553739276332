.expense-amount-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
}

.expense-amount-updated {
  background-color: #e8f1fe;
  font-size: 9px;
  color: #1877f2;
  padding-inline: 5px;
  padding-block: 2px;
  border-radius: 2px;
}

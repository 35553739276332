.reminder-month{
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    color: #500472;
    font-weight: 700;
}

.reminder-column-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
 }

.reminder-column{
    color:#898995 ;
    font-size: 1rem;
}
.inputs-container {
  margin-top: 1rem;
}

.modified-months-container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  background-color: #ffffff;
  font-size: 0.8rem;
  gap: 0.2rem;
  align-items: center;
  padding-block: 0.5rem;
  padding-inline: 0.3rem;
  border-radius: 6px;
  font-family: Manrope;
  flex-wrap: wrap;
}

.modified-months-text {
  color: #898995;
}

.modified-months {
  background-color: #f6f6f7;
  border-radius: 6px;
  padding-inline: 0.5rem;
  padding-block: 0.2rem;
}

.view-all-text {
  color: #1877f2;
  font-weight: 500;
  cursor: pointer;
}

.choose-action-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Manrope;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.choose-action-text {
  color: #898995;
  font-size: 0.9rem;
}

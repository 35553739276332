/** @format */

.associated-goals-container {
   display: flex;
   flex-direction: row;
   gap: 0.3rem;
   flex-wrap: wrap;
}

.associated-goal {
   background-color: rgba(80, 4, 114, 0.1);
   color: rgba(80, 4, 114, 1);
   padding-inline: 0.5rem;
   padding-block: 0.2rem;
   border-radius: 5px;
}
.view-more {
   color: #1877f2;
   cursor: pointer;
}

.associated-goals-text {
   display: flex;
   flex-direction: row;
   gap: 0.5rem;
}

.amount-wrapper {
   display: flex;
   flex-direction: row;
}

#transfer-details-table-body {
  display: block;
  max-height: 48vh;
  overflow: auto;
  border-collapse: collapse;
}
#transfer-details-table-header-container,
#transfer-details-table-body #transfer-details-table-tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
  background-color: #ffffff;
}
#transfer-details-table-body {
  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 158px 0px 0px 103px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 158px 0px 0px 103px;
    margin-right: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 158px 0px 0px 103px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
}

#transfer-details-table {
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
  border-radius: 10px; 
}

 
#transfer-details-table-th {
  padding: 0.8rem;
  font-size: 0.8rem;
  text-align: left;
  color: #898995;
  font-family: Manrope;
  &:hover {
    background-color: rgba(137, 137, 149, 0.3);
  }
}

#transfer-details-table-header-container {
  width: calc(100% - 0em);
  overflow: hidden;
}

#transfer-details-table-td {
  text-align: left;
  color: #14142b;
  font-family: Manrope;
  font-weight: 600;
  padding: 0.7rem;
  font-size: 0.8rem;
  border: 1px solid #e0e0e0;
}

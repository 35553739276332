.check-box-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:  space-between;
}

.learn-more-text {
  font-size: 0.9rem;
  color: #1877f2;
}

.checkbox-container {
  background-color: grey;
  width: 75%;
}

/** @format */

.logout-container {
   position: absolute;
   background: #f2f4f6;
   bottom: 3%;
   position: absolute;
   width: 100%;
   padding: 0.4rem;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
}

.logout-name {
   font-size: 1rem;
   font-weight: 600;
   font-family: Manrope;
   color: #14142b;
}

.logout-email {
   font-size: 0.8rem;
   font-weight: 400;
   font-family: Manrope;
   color: #898995;
}

.avatar-and-name-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 0.5rem;
}

/** @format */

.loader {
   transform: translateZ(1px);
}

.loader-container {
   background-color: rgba(0, 0, 0, 0.4);
   position: absolute;
   z-index: 2000;
   height: 100vh;
   width: 100vw;
   align-items: center;
   display: flex;
   justify-content: center;
}

.loader:after {
   content: "₹";
   display: inline-block;
   width: 70px;
   height: 70px;
   border-radius: 50%;
   text-align: center;
   line-height: 65px;
   font-size: 50px;
   font-weight: bold;
   background: rgba(80, 4, 114, 1);
   color: #fff;
   border: 1px solid;
   box-sizing: border-box;
   box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
   animation: coin-flip 4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes coin-flip {
   0%,
   100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
   }
   0% {
      transform: rotateY(0deg);
   }
   50% {
      transform: rotateY(180deg);
      /* animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); */
   }
   100% {
      transform: rotateY(360deg);
   }
}

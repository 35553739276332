/** @format */

.delete-modal-header-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   width: 100%;
}

.delete-modal-header-text {
   font-size: 1.4rem;
   font-weight: 700;
}

.delete-modal-delete-message {
   text-align: center;
   font-size: 1rem;
   color: #898995;
   margin-top: 1rem;
   margin-bottom: 1rem;
   width: 90%;
}

.delete-modal-alert-container {
   width: 100%;
   margin-bottom: 1rem;
}

.delete-modal-button-container {
   display: flex;
   flex-direction: row;
   gap: 1rem;
   justify-content: flex-end;
   width: 100%;
   margin-top: 1.5rem;
}

.goal-status-ongoing{
    padding-inline: 10px;
    padding-block: 5px;
    color:rgba(255, 183, 3, 1) ;
    background-color: rgba(255, 183, 3, 0.1);
    width: fit-content;
    border-radius: 5px;
}

.goal-status-completed{
    padding-inline: 10px;
    padding-block: 5px;
    color: rgba(18, 183, 105, 1) ;
    background-color: rgba(18, 183, 105, 0.1);
    width: fit-content;
    border-radius: 5px;
}
.chips-selection-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  align-items: center;
}

.chips-selection-title{
    color: #898995;
    margin-right: 0.5rem;
    white-space: nowrap;
}
/** @format */

.chips-selection-wrapper {
   margin-top: 1rem;
   width: fit-content;
}

.select-container {
   color: #500472;
   padding-inline: 1rem;
   padding-block: 0.5rem;
   font-family: Manrope;
   background-color: rgba(238, 230, 241, 1);
   border-radius: 8px;
   padding-right: 2rem !important;
}

#select-option-container * {
   color: #500472;
   padding-inline: 2rem;
   padding-block: 0.5rem;
   font-family: Manrope;
   background-color: rgba(238, 230, 241, 1);
}

.choose-year-and-month-text {
   color: #898995;
}
.choose-year-and-month-container {
   display: flex;
   flex-direction: row;
   gap: 1rem;
   align-items: center;
}

.choosed-year-and-month {
   background-color: #eee6f1;
   padding: 0.5rem 1rem;
   border-radius: 8px;
   color: #500472;
   font-weight: 600;
   cursor: pointer;
}

.choose-year-and-month-popover-container {
   height: 14rem;
   width: 22rem;
   background-color: #fff;
   position: absolute;
   z-index: 1000;
   border-radius: 8px;
   box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
   display: flex;
   flex-direction: column;
}

.year-and-month-sub-container-wrapper {
   display: flex;
   flex-direction: row;
   padding: 0.5rem;
   height: 85%;
}

.choose-year-and-month-popover-container .year-container {
   flex: 0.4;
   padding: 0.5rem;
}
.choose-year-and-month-popover-container .months-container {
   flex: 0.6;
   padding: 0.5rem;
}
.year-sub-text-inactive {
   color: #898995;
   padding: 0.2rem 1rem;
   border-radius: 1rem;
   cursor: pointer;
}

.year-sub-text-active {
   color: #fff;
   background-color: #500472;
   padding: 0.2rem 1rem;
   border-radius: 1rem;
   cursor: pointer;
}

option:hover {
   background-color: yellow;
}

.select-row {
   display: flex;
   flex-direction: row;
   align-items: center;
   /* background-color: red; */
   width: 14rem;
   justify-content: space-between;
   margin-top: 0.5rem;
   margin-bottom: 0.5rem;
}

.select-label {
   font-family: Manrope;
   color: #898995;
   font-size: 0.9rem;
}

.line {
   width: 1px;
   height: 100%;
   background-color: #8989955d;
}

.year-sub-container {
   display: flex;
   flex-direction: column;
   gap: 1rem;
   align-items: flex-start;
   overflow-y: scroll;
   margin-top: 0.5rem;
   height: 80%;
   &::-webkit-scrollbar {
      width: 2px;
      border-radius: 158px 0px 0px 103px;
   }

   &::-webkit-scrollbar-track {
      border-radius: 158px 0px 0px 103px;
      margin-right: 0.3rem;
   }

   &::-webkit-scrollbar-thumb {
      background: #e8e8ea;
      border-radius: 158px 0px 0px 103px;
   }

   ::-webkit-scrollbar-thumb:hover {
      background: #b30000;
   }
}

.month-sub-container {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-gap: 0.2rem;
   margin-top: 0.5rem;
   cursor: pointer;
}

.month-sub-text-active {
   color: #fff;
   background-color: #500472;
   padding: 0.2rem 1rem;
   border-radius: 1rem;
   cursor: pointer;
}

.month-sub-text-inactive {
   color: #898995;
   padding: 0.2rem 1rem;
   border-radius: 1rem;
   cursor: pointer;
}

.done-text {
   text-align: right;
   padding-right: 1.5rem;
   color: #500472;
   font-weight: 700;
   cursor: pointer;
}

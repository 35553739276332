.expense-type-fixed{
    color: #15616D;
    background: rgba(21, 97, 109, 0.1);
    width: fit-content;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    font-family: Manrope;
}

.expense-type-variable{
    color: #FF7D00;
    background: #FFF2E6;
    width: fit-content;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    font-family: Manrope;
}
.income-summary-cell-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:0.3rem
}

.income-summary-cell-line {
  /* width: 3px;
  height: 100%;
  background-color: #ffbc99;
  margin-top: 10px;
  margin-bottom: 10px;
  color:transparent;
  border-left:1px solid #ffbc99; */
  border-left: 3px solid #ffbc99;
  height: 15px;
  width: 2px;
}

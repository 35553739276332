input[type='number'] {
  -moz-appearance: textfield;
  border: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  display: none;
  border: 0;
}
textarea:focus,
input:focus {
  outline: none;
}

.no-of-entries-container {
  border: 1px solid #e8e8ea;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  border-radius: 6px;
  overflow: hidden;
  height: 68%;
  align-items: center;
}

.no-of-entries-input {
  border-right: thin;
  width: 2rem;
  text-align: center;
}

/** @format */

.reminder-row-container {
   display: flex;
   flex-direction: column;
}

.reminder-row {
   display: flex;
   flex-direction: row;
   background-color: red;
   justify-content: space-between;
}

#reminder-table-row {
   background-color: #ffffff;
   padding: 2rem;
   margin-top: 1rem;
   border-radius: 12px 0px 0px 12px;
}

#reminder-table-header {
   color: #898995;
   font-weight: 500;
   border: 0px;
   padding: 1rem;
}

#reminder-table {
   width: 100%;
   border: 0px;
   border-spacing: 0 15px;
}
td:first-child {
   -moz-border-radius: 10px 0 0 10px;
   -webkit-border-radius: 10px 0 0 10px;
}
td:last-child {
   -moz-border-radius: 0 10px 10px 0;
   -webkit-border-radius: 0 10px 10px 0;
}

#table-cell-center-align {
   text-align: center;
}

#reminder-table-body {
   display: block;
   overflow: auto;
   border-collapse: collapse;
   height: 60vh;
}
#reminder-table-header-container,
#reminder-table-body #reminder-table-row {
   display: table;
   width: 100%;
   table-layout: fixed; /* even columns width , fix width of table too*/
}

#reminder-table-body {
   &::-webkit-scrollbar {
      width: 2px;
   }

   &::-webkit-scrollbar-track {
      margin-right: 0.1rem;
   }

   &::-webkit-scrollbar-thumb {
      background: #500472;
   }

   ::-webkit-scrollbar-thumb:hover {
      background: #b30000;
   }
}

#reminder-type {
   background-color: #eee6f1;
   height: 6rem;
   text-align: center;
   color: #500472;
   font-weight: 700;
   width: 7rem;
}
#reminder-type-header {
   width: 7rem;
   border: 0px;
}

.reminder-amount-paid-container {
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
}
.reminder-amount-paid {
   background: #f6f6f7;
   padding-inline: 1rem;
   padding-block: 0.5rem;
   border-radius: 16px;
   width: 8rem;
}

.reminder-transfer-details-row {
   display: flex;
   flex-direction: row;
   justify-content: center;
   gap: 0.5rem;
}

.reminder-transfer-bank {
   color: #500472;
}

.goal-completed-container {
   margin-left: 2rem;
}

.reminder-action-container {
   display: flex;
   flex-direction: column;
   gap: 0.6rem;
   align-items: center;
}
.mark-as-complete {
   background-color: #12b769;
   width: fit-content;
   font-size: 0.9rem;
   padding-inline: 0.8rem;
   padding-block: 0.3rem;
   border-radius: 8px;
   color: #ffffff;
   cursor: pointer;
}

.skip-this-month {
   color: red;
   font-size: 0.9rem;
   font-weight: 500;
   cursor: pointer;
}

.revive {
   background-color: #fbe6ea;
   width: fit-content;
   font-size: 0.9rem;
   padding-inline: 0.8rem;
   padding-block: 0.3rem;
   border-radius: 8px;
   color: #d90429;
   cursor: pointer;
}

.revive-disabled {
   background-color: #c4c4ca;
   width: fit-content;
   font-size: 0.9rem;
   padding-inline: 0.8rem;
   padding-block: 0.3rem;
   border-radius: 8px;
   color: #fff;
   cursor: pointer;
}
.skip_this_month {
   cursor: pointer;
}

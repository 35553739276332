/** @format */

#main-table-body {
   display: block;
   height: calc(100vh - 20vh);
   overflow: auto;
   border-collapse: collapse;
}
#main-table-header-container,
#main-table-body #main-table-tr {
   display: table;
   width: 100%;
   table-layout: fixed; /* even columns width , fix width of table too*/
   background-color: #ffffff;
}
#main-table-body {
   border: 1px solid #e5e7eb;

   &::-webkit-scrollbar {
      width: 2px;
      border-radius: 158px 0px 0px 103px;
   }

   &::-webkit-scrollbar-track {
      border-radius: 158px 0px 0px 103px;
      margin-right: 0.3rem;
   }

   &::-webkit-scrollbar-thumb {
      background: #500472;
      border-radius: 158px 0px 0px 103px;
   }

   ::-webkit-scrollbar-thumb:hover {
      background: #b30000;
   }
}

#main-table {
   width: 98%;
   overflow: hidden;
   border-radius: 8px;
   border-collapse: collapse;
}

#main-table-th {
   padding: 0.7rem;
   font-size: 0.8rem;
   text-align: left;
   background-color: #ffffff;
   color: #898995;
   font-family: Manrope;
   border: 0;
   &:hover {
      background-color: rgba(137, 137, 149, 0.3);
   }
}

#main-table-header-container {
   width: calc(100% - 0em) /* scrollbar is average 1em/16px width, remove it from thead width */;
   overflow: hidden;
}

#main-table-td {
   /* border: 1px solid #e5e7eb; */
   text-align: left;
   background-color: #ffffff;
   color: #14142b;
   font-family: Manrope;
   font-weight: 600;
   padding: 0.7rem;
   font-size: 0.8rem;
   word-break: break-all;
}

/** @format */

.side-bar-container {
   height: 100vh;
   background-color: #ffffff;
   border-right: 1px solid #e8e8ea;
   position: relative;
}

.side-bar-main-container {
   padding: 2rem;
   margin-top: -0.5rem;
   overflow-y: scroll;
   height: 80%;

   &::-webkit-scrollbar {
      width: 2px;
      border-radius: 158px 0px 0px 103px;
   }

   &::-webkit-scrollbar-track {
      border-radius: 158px 0px 0px 103px;
      margin-right: 0.3rem;
   }

   &::-webkit-scrollbar-thumb {
      background: lightgrey;
      border-radius: 158px 0px 0px 103px;
   }

   ::-webkit-scrollbar-thumb:hover {
      background: #b30000;
   }
}

.side-bar-title {
   font-size: 1rem;
   font-weight: 500;
   margin-bottom: 0.5rem;
   margin-top: 0.5rem;
   font-family: Manrope;
   color: #898995;
}

.side-bar-subtitles-container {
   margin-left: 0.5rem;
   display: flex;
   flex-direction: column;
}

.side-bar-tab-margins {
   margin-top: 0.1rem;
   margin-bottom: 0.1rem;
}

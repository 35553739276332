/** @format */

.inputs-modal-title {
   font-size: 1.5rem;
   font-weight: 500;
   color: #14142b;
   font-style: normal;
}

.inputs-container {
   margin-top: 2rem;
   padding-bottom: 2rem;
}

.input-modals-container {
   position: absolute;
   background-color: #ffffff;
   padding: 1.5rem;
   top: 2.5%;
   bottom: 2.5%;
   right: 2%;
   border-radius: 24px;
   width: 27vw;
   @media (max-width: 480px) {
      position: absolute;
      width: 96%;
      background-color: #fff;
      transform: translate(-50%, -50%);
   }
}

.input-modals-container::-webkit-scrollbar {
   display: none;
}

.input-modal-header-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

.input-modal-children-container {
   height: 95%;
   overflow-y: scroll;
   &::-webkit-scrollbar {
      display: none;
   }
}

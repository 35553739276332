.statistics-card-container{
    background-color: #ffffff;
    padding-inline: 1.3rem;
    padding-inline-end: 2rem;
    padding-top: 1rem;
    border-radius: 8px;
    padding-bottom: 0.7rem;
    gap:0.4rem;
    display: flex;
    flex-direction: column;
}

.statistics-card-title{
    font-family: Manrope;
    color: #898995;
    font-size: 0.9rem;
}

.statistics-card-month{
    font-family: Manrope;
    font-size: 0.7rem;
    color:#14142B;
}

.statistics-card-value{
    font-family: Manrope;
    font-size: 1.2rem;
    font-weight: 600;
}
.header-container {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
  padding-inline: 2rem;
  border-bottom: 1px solid #e8e8ea;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}

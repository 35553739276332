/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
   margin: 0;
   font-family: Manrope;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

svg {
   cursor: pointer;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", "Manrope", monospace;
}

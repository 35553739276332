/** @format */

.center-modal-container {
   position: absolute;
   top: 10%;
   left: 36%;
   transform: translate(-50%, -50%);
   background-color: #ffffff;
   padding: 2rem;
   border-radius: 8px;
   width: 30%;
   align-items: center;
   display: flex;
   flex-direction: column;
}
